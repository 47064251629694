import { getAccessToken } from "rest/common";
import {
  AdminService,
  ApiError,
  GetUserWithAuthTokenModel,
  UserCreateModel,
  UserGeneratePasswordResetModel,
  UserLoginModel,
  UserPatchModel,
  UserResetPasswordModel,
  UserService,
  UserWithoutPasswordModel,
} from "north-manly-squash-api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAnalyticsConext } from "contexts/AnalyticsContext";

const api = {
  useMe: () => {
    return useQuery<UserWithoutPasswordModel | undefined>(
      ["users-me"],
      () =>
        UserService.getUserMe({
          xSquashAuth: getAccessToken(),
        }),
      {
        onError: (err) => {
          if (err instanceof ApiError) {
            if (err.status === 401) {
              // ignore 401 on /me
              return undefined;
            }
          }
        },
      }
    );
  },
  useRefreshMyUser: (user?: UserWithoutPasswordModel) => {
    const queryClient = useQueryClient();

    return useQuery<GetUserWithAuthTokenModel>(
      ["users-me-with-auth"],
      () => UserService.getUserRefresh({ xSquashAuth: getAccessToken() }),
      {
        onSuccess: (data) => {
          window.localStorage.setItem("token", data.accessToken);
          queryClient.setQueryData(["users-me"], data.user);
        },
        enabled: Boolean(user?.id),
      }
    );
  },

  useGetUser: (userid: number) =>
    useQuery(["users-get", userid], () =>
      UserService.getUserById({
        id: userid,
        xSquashAuth: getAccessToken(),
      })
    ),
  useLogin: () => {
    const queryClient = useQueryClient();
    const { setUser } = useAnalyticsConext();
    return useMutation(
      ({ requestBody }: { requestBody: UserLoginModel }) =>
        UserService.postUserLogin({ requestBody }),
      {
        onSuccess: (data) => {
          window.localStorage.setItem("token", data.accessToken);
          setUser(data.user.id);
          queryClient.setQueryData(["users-me"], data.user);
          queryClient.invalidateQueries(["users-me-with-auth"]);
        },
      }
    );
  },

  useCreate: () => {
    const queryClient = useQueryClient();
    const { setUser } = useAnalyticsConext();
    return useMutation(
      (data: { requestBody: UserCreateModel }) =>
        UserService.postUserCreate(data),
      {
        onSuccess: (data) => {
          window.localStorage.setItem("token", data.accessToken);
          setUser(data.user.id);
          queryClient.setQueryData(["users-me"], data.user);
          queryClient.invalidateQueries(["users-me-with-auth"]);
        },
      }
    );
  },

  useResetPassword: () =>
    useMutation((requestBody: { requestBody: UserResetPasswordModel }) =>
      UserService.postUserResetPassword(requestBody)
    ),

  //generate reset token
  userGenerateReset: async (data: UserGeneratePasswordResetModel) => {
    return await UserService.postUserGenerateResetPassword({
      requestBody: data,
    });
  },

  adminGenerateReset: async (userID: number) => {
    return await AdminService.postAdminGenerateResetPassword({
      id: userID,
      xSquashAuth: getAccessToken(),
    });
  },

  search: async (query: string) => {
    return await AdminService.adminSearchUser({
      query,
      xSquashAuth: getAccessToken(),
    });
  },

  editUser: async (requestBody: UserPatchModel, id: number) => {
    return await UserService.patchUser({
      id,
      requestBody,
      xSquashAuth: getAccessToken(),
    });
  },

  useGetPastEvents: (id: number) =>
    useQuery(["users-past-events", id], () =>
      UserService.getUserPastEvents({
        id,
        xSquashAuth: getAccessToken(),
      })
    ),
};
export default api;
