import {
  MatchService,
  MatchState,
  MatchUpdateModel,
  RankService,
  MatchCreateModel,
} from "north-manly-squash-api";
import { getAccessToken } from "rest/common";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

type GetMatches = {
  state: MatchState;
  userId?: number;
  limit?: number;
  offset?: number;
};

const api = {
  useGetRanks: () => useQuery(["ranks-get"], () => RankService.getRanks()),

  useGetMatches: (data: GetMatches) =>
    useQuery(["matches-get", data], () => MatchService.getMatches(data)),

  useCreateMatch: () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({ requestBody }: { requestBody: MatchCreateModel }) =>
        MatchService.postMatch({
          requestBody,
          xSquashAuth: getAccessToken(),
        }),
      {
        onSettled: () => {
          queryClient.invalidateQueries(["matches-get"]);
        },
      }
    );
  },

  usePatchMatch: () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({
        requestBody,
        matchID,
      }: {
        requestBody: MatchUpdateModel;
        matchID: number;
      }) =>
        MatchService.patchMatch({
          requestBody,
          id: matchID,
          xSquashAuth: getAccessToken(),
        }),
      {
        onSettled: () => {
          queryClient.invalidateQueries(["matches-get"]);
        },
      }
    );
  },
};

export default api;
