import React, { useState, useContext, useEffect } from "react";

import { Link, Redirect, useHistory } from "react-router-dom";

import { UserContext } from "contexts/UserContext";
import { validateAccountCreate } from "utils/validation";
import API from "rest/api";
import Information from "components/Information";
import Input from "components/Input";
import Button from "components/Button";
import style from "./style.module.scss";
import { UserCreateModel } from "north-manly-squash-api";
import toast from "react-hot-toast";
import { getStorage, SESSION_KEYS } from "utils/storage";

type Form = UserCreateModel & { password2: string };

const CreateUser = () => {
  const history = useHistory();

  const { user } = useContext(UserContext);
  const { mutate, isSuccess, data, isError } = API.users.useCreate();

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<Partial<Form>>({});

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const redirect = params.get("redirect");
  const path = redirect ? redirect : "/";

  useEffect(() => {
    if (data) {
      history.push(path);
    }
  }, [isSuccess, data, history, path]);

  useEffect(() => {
    setLoading(false);
  }, [isError]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const valid = validateAccountCreate(form as Form);

    if (valid.success) {
      setLoading(true);
      const source = getStorage("SESSION", SESSION_KEYS.source);
      mutate({
        requestBody: {
          ...(form as Form),
          source,
        },
      });
    } else {
      if (valid.err) {
        toast.error(valid.err);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  if (user?.id) return <Redirect to={path} />;

  if (loading) {
    return (
      <Information>
        <h1>Creating your account...</h1>
      </Information>
    );
  }

  const redirectUrl = path !== "/" ? `?redirect=${path}` : "";

  return (
    <Information>
      <h1>Create an account</h1>

      <form>
        <div className={style.inputWrapper}>
          <Input
            label="Email address"
            value={form["email"]}
            handleChange={handleChange}
            name="email"
            type="email"
          />
        </div>
        <div className={style.inputWrapper}>
          <Input
            label="Phone"
            value={form["phone"]}
            handleChange={handleChange}
            name="phone"
            type="text"
          />
        </div>
        <div className={style.inputWrapper}>
          <Input
            label="First name"
            value={form["firstname"]}
            handleChange={handleChange}
            name="firstname"
            type="text"
          />
        </div>
        <div className={style.inputWrapper}>
          <Input
            label="Last name"
            value={form["lastname"]}
            handleChange={handleChange}
            name="lastname"
            type="text"
          />
        </div>
        <div className={style.inputWrapper}>
          <Input
            label="Password"
            value={form["password"]}
            handleChange={handleChange}
            name="password"
            type="password"
          />
        </div>
        <div className={style.inputWrapper}>
          <Input
            label="Confirm password"
            value={form["password2"]}
            handleChange={handleChange}
            name="password2"
            type="password"
          />
        </div>
        <Button text="Create Account" handleClick={handleSubmit} />
      </form>

      <Link to={`/login${redirectUrl}`} className={style.login}>
        Already have an account? Log in here
      </Link>
    </Information>
  );
};

export default CreateUser;
