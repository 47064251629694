import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { EventModel, MatchWithUserDetailsModel } from "north-manly-squash-api";
import {
  IJsonResponse,
  BASE_URL,
  commonAxiosConfig,
  IResultResponse,
} from "rest/common";

export enum PaymentProducts {
  "social1" = "social1",
  "social2" = "social2",
  "ladder-match" = "ladder-match",
}

interface IStripeSecretResponse extends IResultResponse {
  secret: string;
}

export type IUnpaidFeesEvents = Pick<
  EventModel,
  "id" | "name" | "price" | "start" | "type"
> & { user_event_id: number };
export type IUnpaidFeesMatch = Pick<
  MatchWithUserDetailsModel,
  | "id"
  | "match_date"
  | "player_1_firstname"
  | "player_2_firstname"
  | "player_1"
  | "player_2"
  | "player_1_paid"
  | "player_2_paid"
>;
export interface IUnpaidFees extends IResultResponse {
  events: Array<IUnpaidFeesEvents>;
  matches: Array<IUnpaidFeesMatch>;
  originalTotal: number;
  total: number;
  paymentIntentId: string;
  discount: number;
}

export interface ISettleDebts extends IResultResponse {
  paymentSuccess: boolean;
  message: string;
}

const api = {
  getPaymentIntentID: (product: PaymentProducts) => {
    return axios
      .post<null, IJsonResponse<IStripeSecretResponse>>(
        `${BASE_URL}/payments/capture-payment-intent`,
        {
          product,
        },
        commonAxiosConfig
      )
      .then((res) => {
        return res.data;
      });
  },

  useGetUnpaidFees: (id?: string) => {
    return useQuery(["unpaid-fees", id], () => {
      const url = id
        ? `${BASE_URL}/payments/unpaid-fees/${id}`
        : `${BASE_URL}/payments/unpaid-fees`;
      return axios
        .get<null, IJsonResponse<IUnpaidFees>>(url, commonAxiosConfig)
        .then((res) => {
          return res.data;
        });
    });
  },

  settleDebts: (paymentIntentClientSecret: string) => {
    return axios
      .post<null, IJsonResponse<ISettleDebts>>(
        `${BASE_URL}/payments/settle-debts`,
        {
          paymentIntentClientSecret,
        },
        commonAxiosConfig
      )
      .then((res) => {
        return res.data;
      });
  },

  settleDebtsAdmin: (userId: string) => {
    return axios
      .post<null, IJsonResponse<ISettleDebts>>(
        `${BASE_URL}/payments/settle-debts/${userId}`,
        {},
        commonAxiosConfig
      )
      .then((res) => {
        return res.data;
      });
  },
};

export default api;

/*
 /settle-up route calculates all unpaid fees and gives you page of them where you can pay
 it will also give the paymentintent id
 */
