import React from "react";
import API from "rest/api";
import Button from "components/Button";

import { UserEventModel } from "north-manly-squash-api";

interface IProps {
  setOpen: (open: boolean) => void;
  event: UserEventModel;
}

const EditSocial = ({ setOpen, event }: IProps) => {
  const { mutate } = API.events.usePatchUserEvent(event.event_id);

  const handleRemove = () => {
    mutate({
      requestBody: {
        ...event,
        enabled: false,
      },
      userEventId: event.id,
    });

    setOpen(false);
  };

  const handlePaid = () => {
    mutate({
      requestBody: {
        ...event,
        paid: !event.paid,
      },
      userEventId: event.id,
    });

    setOpen(false);
  };

  return (
    <>
      <li>
        <Button handleClick={handleRemove} text="Remove" />
      </li>
      <li>
        <Button handleClick={handlePaid} text="Toggle paid state" />
      </li>
      <li>
        <Button
          type="link"
          internalHref={`/settle-up/${event.user_id}`}
          text="View debts"
        />
      </li>
    </>
  );
};

export default EditSocial;
