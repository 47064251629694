import React, { useState, useContext, useEffect } from "react";

import { useHistory, Link, Redirect } from "react-router-dom";

import { UserContext } from "contexts/UserContext";
import { validateAccountLogin } from "utils/validation";
import API from "rest/api";
import Information from "components/Information";
import Input from "components/Input";
import Button from "components/Button";
import style from "pages/CreateUser/style.module.scss";
import { UserLoginModel } from "north-manly-squash-api";
import toast from "react-hot-toast";

const Login = () => {
  const history = useHistory();

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const redirect = params.get("redirect");
  const path = redirect ? redirect : "/";
  const { user } = useContext(UserContext);

  const { mutate, isLoading, isSuccess, data } = API.users.useLogin();

  const [form, setForm] = useState<Partial<UserLoginModel>>({});

  useEffect(() => {
    if (!data) return;

    history.push(path);
  }, [isSuccess, data, history, path]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const valid = validateAccountLogin(form);

    if (valid.success) {
      mutate({ requestBody: { email: form.email!, password: form.password! } });
    } else {
      if (valid.err) {
        toast.error(valid.err);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  if (user?.id) return <Redirect to={path} />;

  if (isLoading) {
    return (
      <Information>
        <h1>Logging you in...</h1>
      </Information>
    );
  }

  const redirectUrl = path !== "/" ? `?redirect=${path}` : "";

  return (
    <Information>
      <h1>Log in</h1>

      <form>
        <div className={style.inputWrapper}>
          <Input
            label="Email address"
            value={form["email"]}
            handleChange={handleChange}
            name="email"
            type="email"
          />
        </div>
        <div className={style.inputWrapper}>
          <Input
            label="Password"
            value={form["password"]}
            handleChange={handleChange}
            name="password"
            type="password"
          />
        </div>
        <Button text="Log in" handleClick={handleSubmit} />
      </form>

      <Link to={`/create${redirectUrl}`} className={style.login}>
        Need to make an account? Create one here here
      </Link>

      <Link to={`/forgot-password${redirectUrl}`} className={style.login}>
        Forgot your password? Reset it here
      </Link>
    </Information>
  );
};

export default Login;
