import React from "react";
import UserRow from "components/UserRow";
import EventDate from "components/EventDate";
import List from "components/List";

import API from "rest/api";
import Information from "components/Information";
import Button from "components/Button";

import style from "./style.module.scss";
import { MatchWithUserDetailsModel } from "north-manly-squash-api";

const ResultRow = ({
  id,
  player_1,
  player_1_firstname,
  player_1_games,
  player_2,
  player_2_firstname,
  player_2_games,
  match_date,
}: MatchWithUserDetailsModel) => {
  const { mutate } = API.ladder.usePatchMatch();
  const handleSubmit = () => {
    mutate({
      requestBody: {
        approved: true,
      },
      matchID: id,
    });
  };

  return (
    <>
      <td>
        <UserRow id={player_1} name={player_1_firstname} />
      </td>
      <td>
        <UserRow id={player_2} name={player_2_firstname} />
      </td>
      <td>
        <EventDate time={match_date} />
      </td>
      <td>
        {player_1_games} : {player_2_games}
      </td>
      <td>
        <Button text="Approve" handleClick={handleSubmit} />
      </td>
    </>
  );
};

const Approvals = () => {
  const { data, isLoading, isError } = API.ladder.useGetMatches({
    state: "PENDING_APPROVAL",
  });

  if (isLoading || isError) return null;
  const { matches } = data;
  if (matches.length === 0)
    return (
      <Information styles={style.gap}>
        <h3>You have no approvals to enter.</h3>
      </Information>
    );

  const body = matches.map((match) => [<ResultRow {...match} />]);

  return (
    <List
      title="Pending approvals"
      headers={["Challenger", "Opponent", "Date", "Result", "Approve"]}
      body={body}
      columnsInBuilt
    />
  );
};

export default Approvals;
