import Button from "components/Button";

import React from "react";
import API from "rest/api";

import { EventModel } from "north-manly-squash-api";

type IProps = {
  event: EventModel;
  setOpen: (open: boolean) => void;
};
const EditEvent = ({ event, setOpen }: IProps) => {
  const cancelEvent = async () => {
    await updateEvent({ ...event, enabled: false });
  };

  const reinstateEvent = async () => {
    await updateEvent({ ...event, enabled: true });
  };

  const updateEvent = async (event: EventModel) => {
    await API.events.patchEvent(event, event.id);
    setOpen(false);
  };

  return (
    <>
      <li>
        {event.enabled ? (
          <Button handleClick={cancelEvent} text="Cancel event" />
        ) : (
          <Button handleClick={reinstateEvent} text="Re-open event" />
        )}
      </li>
    </>
  );
};

export default EditEvent;
