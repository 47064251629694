import React from "react";
import { useParams, Redirect } from "react-router-dom";
import API from "rest/api";

import style from "./style.module.scss";

import SignupSheet from "./SignUp";
import Information from "components/Information";

import EditEvent from "pages/Event/EditEvent";
import Edit from "components/Edit";

interface ParamTypes {
  eventid?: string;
}

const Event = () => {
  const { eventid } = useParams<ParamTypes>();

  if (eventid === undefined) return <Redirect to="/" />;

  const { isLoading, data } = API.events.useGetEvent(parseInt(eventid));

  if (data === undefined) {
    if (isLoading) return null;
    return (
      <>
        <Information>
          <h2>Event could not be loaded. Sorry!</h2>
        </Information>
      </>
    );
  }

  const { description, enabled, name } = data;

  return (
    <div className={isLoading ? style.loading : undefined}>
      <div className={style.eventArea}>
        <div className={style.titleArea}>
          <h1 className={style.title}>{name}</h1>

          <Edit>
            {(setOpen) => <EditEvent event={data} setOpen={setOpen} />}
          </Edit>

          <h5>{description}</h5>
          {enabled === false && (
            <h1 className={style.cancelled}>This event has been cancelled</h1>
          )}
        </div>
      </div>

      <SignupSheet event={data} />
    </div>
  );
};

export default Event;
