import React, { useEffect, useState } from "react";

import { useHistory, Link } from "react-router-dom";

import { validatePassword } from "utils/validation";
import API from "rest/api";
import Information from "components/Information";
import Input from "components/Input";
import Button from "components/Button";
import style from "pages/CreateUser/style.module.scss";

const ResetPassword = () => {
  const history = useHistory();

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const token = params.get("token");

  const { mutate, isLoading, isSuccess } = API.users.useResetPassword();

  const [password, setPassword] = useState("");

  useEffect(() => {
    if (isSuccess) {
      history.push("/login");
    }
  }, [isSuccess, history]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const valid = validatePassword(password);

    if (valid && token) {
      mutate({ requestBody: { password, token } });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPassword(value);
  };

  if (isLoading) {
    return (
      <Information>
        <h1>Resetting...</h1>
      </Information>
    );
  }

  return (
    <Information>
      <h1>Reset your password</h1>

      <form>
        <div className={style.inputWrapper}>
          <Input
            label="Password"
            value={password}
            handleChange={handleChange}
            name="password"
            type="password"
          />
        </div>
        <Button text="Reset" handleClick={handleSubmit} />
      </form>

      <Link to="/create" className={style.login}>
        Need to make an account? Create one here here
      </Link>
    </Information>
  );
};

export default ResetPassword;
