import React from "react";
import EditEvent from "pages/Event/EditEvent";
import Edit from "components/Edit";
import SignupSheet from "pages/Event/SignUp";
import style from "./style.module.scss";
import Information from "components/Information";
import { EventModel } from "north-manly-squash-api";
import API from "rest/api";

const ExpandedEvent = ({ event }: { event: EventModel }) => {
  const { name, description } = event;

  const { data, isSuccess } = API.events.useGetEvent(event.id);

  return (
    <Information gap>
      <h1 className={style.title}>{name}</h1>
      <Edit>{(setOpen) => <EditEvent event={event} setOpen={setOpen} />}</Edit>

      <h5 className={style.description}>{description}</h5>

      {isSuccess && <SignupSheet event={data} />}
    </Information>
  );
};

export default ExpandedEvent;
