import React from "react";
import style from "../BoxLink/style.module.scss";
import Information from "components/Information";
import Button from "components/Button";
import { Link } from "react-router-dom";
import { EventModel } from "north-manly-squash-api";
import { formatISO } from "date-fns";
import classnames from "classnames";

const PastEvent = (event: EventModel) => {
  const { start, id, name } = event;

  return (
    <Link
      to={{ pathname: `event/${id}`, state: event }}
      className={style["no-link"]}
    >
      <Information
        styles={classnames(style["table-outer"], style["past-event"])}
      >
        <h3>{name}</h3>
        <h5>
          {formatISO(new Date(parseInt(start)), { representation: "date" })}
        </h5>
        <Button text={"View event"} />
      </Information>
    </Link>
  );
};

export default PastEvent;
