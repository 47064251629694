export enum SESSION_KEYS {
  source = "source",
}
export enum LOCAL_KEYS {}

type StorageType = "SESSION" | "LOCAL";

type StorageValue = string;

export const setStorage = (
  type: StorageType,
  key: SESSION_KEYS,
  value: StorageValue
) => {
  const storage =
    type === "SESSION" ? window.sessionStorage : window.localStorage;
  storage.setItem(key, value);
};

export const deleteStorage = (type: StorageType, key: SESSION_KEYS) => {
  const storage =
    type === "SESSION" ? window.sessionStorage : window.localStorage;
  storage.removeItem(key);
};

export const getStorage = (type: StorageType, key: SESSION_KEYS) => {
  const storage =
    type === "SESSION" ? window.sessionStorage : window.localStorage;
  return storage.getItem(key) ?? undefined;
};
