import Information from "components/Information";
import React, { useEffect } from "react";
import API from "rest/api";
import { useParams } from "react-router-dom";

export const BookingConfirm: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const { mutate, isLoading, isError } = API.booking.useConfirmBooking();
  useEffect(() => {
    if (id) {
      mutate(id);
    }
  }, []);

  if (!id || isError) {
    return (
      <Information>
        <h2>Invalid url, please contact northmanlysquashclub@gmail.com</h2>
      </Information>
    );
  }

  if (isLoading) {
    return (
      <Information>
        <h2>Loading...</h2>
      </Information>
    );
  }

  return (
    <Information>
      <h2>Booking confirmed!</h2>
    </Information>
  );
};
