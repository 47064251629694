import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./style.module.scss";

const Slash = () => <span className={styles.slash}>/</span>;

const buildCrumbs = (location: Location) => {
  const crumbs = [
    <Link to="/" key="home">
      Home
    </Link>,
  ];

  if (location.pathname.includes("competition")) {
    crumbs.push(
      <>
        <Slash key="slash1" />
        <Link to="/competition" key="competition">
          Ladder league
        </Link>
      </>
    );
    return crumbs;
  }
  if (location.pathname.includes("social")) {
    crumbs.push(
      <>
        <Slash key="slash2" />
        <Link to="/social" key="social">
          Social events
        </Link>
      </>
    );
    return crumbs;
  }

  if (location.pathname.includes("event")) {
    crumbs.push(<Slash key="slash" />);
    crumbs.push(
      <Link to="/social" key="social">
        Social events
      </Link>
    );
    crumbs.push(<Slash key="slash2" />);
    crumbs.push(
      <Link to={location.pathname} key="socialevent">
        Event
      </Link>
    );
    return crumbs;
  }

  if (location.pathname.includes("beginners")) {
    crumbs.push(<Slash key="slash" />);
    crumbs.push(
      <Link to="/social" key="social">
        Social events
      </Link>
    );
    crumbs.push(<Slash key="slash2" />);
    crumbs.push(
      <Link to={location.pathname} key="socialevent">
        Beginners
      </Link>
    );
    return crumbs;
  }

  return crumbs;
};

const Breadcrumbs = () => {
  const location = useLocation();
  const crumbs = buildCrumbs(location as unknown as Location);
  return <span className={styles.crumbs}>{crumbs}</span>;
};

export default Breadcrumbs;
