import axios from "axios";

import events from "rest/events";
import ladder from "rest/ladder";
import users from "rest/users";
import shop from "rest/shop";
import notifications from "rest/notifications";
import admin from "rest/admin";
import payments from "rest/payments";
import publicAvailability from "rest/public_availabilities";
import ai from "rest/ai";
import forms from "rest/forms";
import booking from "rest/booking";
import { BASE_URL } from "rest/common";
import { OpenAPI } from "north-manly-squash-api";

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error.response &&
      (error.response.status === 403 || error.response.status === 401)
    ) {
      //redirect to auth
      window.location.href = `${window.location.origin}/login?redirect=${window.location.pathname}`;
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  config.headers.preauthurl = window.location.pathname;
  const token = window.localStorage.getItem("token");
  config.headers["x-squash-auth"] = `Bearer ${token}`;
  return config;
});

OpenAPI.BASE = BASE_URL;

const API = {
  events,
  ladder,
  users,
  shop,
  notifications,
  admin,
  payments,
  publicAvailability,
  ai,
  forms,
  booking,
};

export default API;
