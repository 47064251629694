import React, { useState, useContext } from "react";
import List from "components/List";
import UserRow from "components/UserRow";
import { UserContext } from "contexts/UserContext";
import Button from "components/Button";
import API from "rest/api";

import Edit from "components/Edit";
import EditChallenge from "components/EditConfigs/edit-challenge";
import EventDate from "components/EventDate";
import Modal from "components/Modal";
import style from "./style.module.scss";
import {
  MatchUpdateModel,
  MatchWithUserDetailsModel,
} from "north-manly-squash-api";

interface IAcceptProps {
  match: MatchWithUserDetailsModel;
  skipCheckModal?: boolean;
}

export const AcceptChallenge = ({
  match,
  skipCheckModal = false,
}: IAcceptProps) => {
  const { user } = useContext(UserContext);

  const [isOpen, setIsOpen] = useState(false);
  const { mutate, isLoading } = API.ladder.usePatchMatch();

  const { id, accepted, player_2, player_1_firstname } = match;

  const patchChallenge = async (match: MatchUpdateModel) => {
    setIsOpen(false);

    mutate({ requestBody: match, matchID: id });
  };

  const handleAccept = () => {
    patchChallenge({
      ...match,
      accepted: true,
    });
  };

  const handleDecline = () => {
    patchChallenge({
      ...match,
      declined: true,
    });
  };

  const handleOpenModal = () => {
    if (skipCheckModal) {
      handleAccept();
      return;
    }
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  if (accepted) return <span>Accepted</span>;
  if (!user?.id) return <span>Pending</span>;
  if (player_2 !== user.id) return <span>Pending</span>;

  return (
    <>
      <div className={style["accept-challenge-buttons"]}>
        <Button
          disabled={isLoading}
          text={"Decline"}
          handleClick={handleDecline}
          primary={false}
        />
        <Button
          disabled={isLoading}
          text={"Accept"}
          handleClick={handleOpenModal}
        />
      </div>
      {isOpen && (
        <Modal setOpen={setIsOpen}>
          <h4>
            If you accept this challenge your phone number will be shared with{" "}
            {player_1_firstname}
          </h4>
          <div className={style["accept-challenge-buttons"]}>
            <Button
              text={"cancel"}
              handleClick={handleCloseModal}
              primary={false}
            />
            <Button text={"Accept"} handleClick={handleAccept} />
          </div>
        </Modal>
      )}
    </>
  );
};
interface IProps {
  matches: MatchWithUserDetailsModel[];
}

const Challenges = ({ matches }: IProps) => {
  const body = matches.map((match) => {
    const {
      player_1,
      player_1_firstname,
      player_2,
      player_2_firstname,
      accepted,
      match_date,
    } = match;
    const challengeStyle = match_date || accepted ? style.win : style.lose;
    return [
      <>
        <td>
          <UserRow id={player_1} name={player_1_firstname} />
        </td>
        <td>
          <UserRow id={player_2} name={player_2_firstname} />
        </td>

        <td className={challengeStyle}>
          {match_date ? (
            <EventDate time={match_date} />
          ) : (
            <AcceptChallenge match={match} />
          )}

          <Edit>
            {(setOpen) => <EditChallenge setOpen={setOpen} {...match} />}
          </Edit>
        </td>
      </>,
    ];
  });

  return (
    <List
      title="Upcoming challenges"
      headers={["Challenger", "Opponent", "Accepted"]}
      body={body}
      columnsInBuilt={true}
    />
  );
};
export default Challenges;
