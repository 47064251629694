import BoxLink from "components/BoxLink";
import Information from "components/Information";
import API from "rest/api";
import { add, set, subDays } from "date-fns";
import PastEvent from "components/PastEvent";
import style from "./style.module.scss";

const pastDate = set(subDays(new Date(), 7), {
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
}).getTime();
const nextWeek = set(add(new Date(), { days: 7 }), {
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
}).getTime();
const now = set(new Date(), {
  hours: 24,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
}).getTime();

const Social = () => {
  const { isLoading, isError, data } = API.events.useGetEvents({
    endEpoch: nextWeek,
  });

  const { data: pastEvents } = API.events.useGetEvents({
    startEpoch: pastDate,
    endEpoch: now,
    limit: 3,
  });

  if (isLoading || isError) return null;
  const { events } = data;

  if (events.length === 0)
    return (
      <Information>
        <h3>There are no upcoming events</h3>
      </Information>
    );

  const mondayEvents = events.filter(
    (ev) => ev.type === "SOCIAL_ADVANCED" || ev.type === "SOCIAL_INTERMEDIATE"
  );
  const tuesdayEvents = events.filter((ev) => ev.type === "SOCIAL_BEGINNER");

  const tournaments = events.filter(
    (ev) => ev.type === "TOURNAMENT_TIMED" || ev.type === "TOURNAMENT_WEEKEND"
  );

  const isTuesday = new Date().getDay() === 2;

  return (
    <>
      {mondayEvents.length > 0 && !isTuesday && (
        <>
          <h1 className={style.heading}>Monday events</h1>
          {mondayEvents.map((event) => (
            <BoxLink {...event} key={event.id} link={"/event"} />
          ))}
        </>
      )}

      {tuesdayEvents.length > 0 && (
        <>
          <h1 className={style.heading}>Tuesday events</h1>
          {tuesdayEvents.map((event) => (
            <BoxLink {...event} key={event.id} link={"/event"} />
          ))}
        </>
      )}

      {tournaments.length > 0 && (
        <>
          <h1 className={style.heading}>Tournaments</h1>
          {tournaments.map((event) => (
            <BoxLink {...event} key={event.id} link={"/event"} />
          ))}
        </>
      )}

      {pastEvents && (
        <>
          <h1 className={style.heading}>Past events</h1>
          {pastEvents.events.map((event) => (
            <PastEvent {...event} key={event.id} />
          ))}
        </>
      )}
    </>
  );
};

export default Social;
