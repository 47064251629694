import List from "components/List";
import Register from "components/Register";
import React, { useContext } from "react";
import { inFuture } from "utils/compareTime";
import style from "./style.module.scss";
import { UserContext } from "contexts/UserContext";
import API from "rest/api";
import UserRow from "components/UserRow";

import Edit from "components/Edit";
import EditSocial from "components/EditConfigs/edit-social";
import Paid from "components/Paid";

import ManualRegistration from "components/ManualRegistration";
import { EventModel } from "north-manly-squash-api";
import { WrappedAdminControl } from "components/AdminControl";

interface IProps {
  event: EventModel;
}

const EVENT_COMPLETED_OFFSET = 1000 * 60 * 60 * 12; // 12 hours

const SignupSheet = ({ event }: IProps) => {
  const { user: currentUser } = useContext(UserContext);
  const { mutate } = API.events.usePatchUserEvent(event.id);

  const { open, start, spots, participants = [] } = event;

  let registeredUsers = 0;
  let alreadyRegistered = false;
  for (let i = 0; i < participants.length; i += 1) {
    const {
      user: { id },
      user_event: { enabled },
    } = participants[i];
    if (enabled) {
      registeredUsers += 1;
    }

    if (enabled && currentUser?.id === id) {
      alreadyRegistered = true;
    }
  }
  const isFull = spots <= registeredUsers;

  const isOpen = !inFuture(open);
  const eventIsFinished = !inFuture(
    (new Date(parseInt(start)).getTime() + EVENT_COMPLETED_OFFSET).toString()
  );

  const removeEntry = async (userEventId: number) => {
    await mutate({
      requestBody: {
        enabled: false,
      },
      userEventId,
    });
  };

  let nameList = participants
    .filter(({ user_event }) => user_event.enabled)
    .map(({ user, user_event }) => [
      <div className={style.row}>
        <UserRow id={user.id} name={`${user.firstname}`} />

        {user_event.id > 0 && (
          <>
            {currentUser?.id === user.id && (
              <button
                className={style.remove}
                title={"Remove me from this event"}
                onClick={() => removeEntry(user_event.id)}
              ></button>
            )}
            <Edit>
              {(setOpen) => <EditSocial setOpen={setOpen} event={user_event} />}
            </Edit>
          </>
        )}

        <Paid paid={user_event.paid} />
      </div>,
    ]);

  if (nameList.length === 0) {
    nameList.push([<div className={style.row}>No registrations yet.</div>]);
  }

  const unregisteredUsers = participants
    .filter(({ user_event }) => user_event.enabled === false)
    .map(({ user, user_event }) => [
      <div className={style.row}>
        <UserRow id={user.id} name={`${user.firstname}`} />

        {user_event.id > 0 && (
          <>
            <Edit>
              {(setOpen) => <EditSocial setOpen={setOpen} event={user_event} />}
            </Edit>
          </>
        )}

        <Paid paid={user_event.paid} />
      </div>,
    ]);

  const title = `${registeredUsers}/${event.spots} registered`;

  return (
    <div className={style.signup}>
      <Register
        registerCTA={"Sign up for this event"}
        eventId={event.id}
        isFull={isFull}
        isOpen={isOpen}
        alreadyRegistered={alreadyRegistered}
        isComplete={eventIsFinished}
        isFree={event.price === 0}
      />

      <List
        headers={[title]}
        body={nameList}
        informationStyles={style["list-custom-style"]}
      />
      <ManualRegistration event_id={event.id} />

      <WrappedAdminControl>
        {unregisteredUsers.length > 0 && (
          <List
            headers={["Users who have unregistered"]}
            body={unregisteredUsers}
            informationStyles={style["list-custom-style"]}
          />
        )}
      </WrappedAdminControl>
    </div>
  );
};

export default React.memo(SignupSheet);
