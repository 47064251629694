export enum Actions {
  "book_court" = "book_court",
  "top_nav_home" = "top_nav_home",
  "top_nav_social" = "top_nav_social",
  "top_nav_competition" = "top_nav_competition",
  "top_nav_shop" = "top_nav_shop",
  "top_nav_faq" = "top_nav_faq",
  "side_nav_competition" = "side_nav_competition",
  "side_nav_social" = "side_nav_social",
  "side_nav_contact" = "side_nav_contact",
  "side_nav_shop" = "side_nav_shop",
  "login" = "login",
  "create_account" = "create_account",
  "social_signup" = "social_signup",
  "social_pay_now" = "social_pay_now",
  "social_pay_later" = "social_pay_later",
  "social_prev_event" = "social_prev_event",
  "social_next_event" = "social_next_event",
  "toggle_mobile_menu" = "toggle_mobile_menu",
  "challenge_opponent_modal" = "challenge_opponent_modal",
  "challenge_pending_modal" = "challenge_pending_modal",

  "purchase" = "purchase",
  "begin_checkout" = "begin_checkout",
}

export enum Categories {
  "button" = "button",
  "link" = "link",
  "modal" = "modal",
  "payment_flow" = "payment_flow",
}
