import Information from "components/Information";
import styles from "components/Information/style.module.scss";
import React from "react";
import ExpandedEvent from "components/ExpandedEvent";
import API from "rest/api";
import { add, subDays } from "date-fns";
import PastEvent from "components/PastEvent";
import style from "../Social/style.module.scss";

const pastDate = subDays(new Date(), 20).getTime();
const nextWeek = add(new Date(), { days: 6, hours: 18 }).getTime();
const now = new Date().getTime();
const Beginners = () => {
  const { isLoading, isError, data } = API.events.useGetEvents({
    eventType: "SOCIAL_BEGINNER",
    endEpoch: nextWeek,
  });

  const { data: pastEvents } = API.events.useGetEvents({
    eventType: "SOCIAL_BEGINNER",
    startEpoch: pastDate,
    endEpoch: now,
    limit: 3,
  });

  if (isLoading || isError) return null;
  const { events } = data;

  return (
    <>
      <Information>
        <h2>Tuesday social night for beginners</h2>
        <p>Play with other people who are new to squash</p>
      </Information>

      {events.map((event) => (
        <ExpandedEvent event={event} key={event.id} />
      ))}

      <Information styles={styles.gap}>
        <h2>Join the group chat!</h2>
        <a
          href="https://chat.whatsapp.com/KdPAcYFNQ2h080QRGMq6ck"
          target="_blank"
          rel="noopener noreferrer"
        >
          WhatsApp group
        </a>
      </Information>

      {pastEvents && (
        <>
          <h1 className={style.heading}>Past events</h1>
          {pastEvents.events.map((event) => (
            <PastEvent {...event} key={event.id} />
          ))}
        </>
      )}
    </>
  );
};

export default Beginners;
