import { UserCreateModel, UserLoginModel } from "north-manly-squash-api";

import EmailValidator from "email-validator";

export const validateEmail = (email: string = ""): boolean => {
  return EmailValidator.validate(email);
};

export const validatePhone = (phone: string = ""): boolean => {
  return true;
};

const validateName = (name: string): boolean => {
  return name.length > 2;
};

const validatePassword = (password?: string): boolean => {
  return Boolean(password && password.length > 5);
};

const validatePasswordMatch = (
  password1: string,
  password2: string
): boolean => {
  return password1 === password2;
};

const validateAccountCreate = ({
  email = "",
  phone = "",
  firstname = "",
  lastname = "",
  password = "",
  password2 = "",
}: UserCreateModel & { password2: string }) => {
  if (!validateEmail(email))
    return {
      success: false,
      err: "Please enter a valid email address",
    };

  if (!validatePhone(phone)) {
    return {
      success: false,
      err: "Please enter a valid phone number",
    };
  }

  if (!validateName(firstname))
    return {
      success: false,
      err: "First name needs to be longer than 3 characters",
    };

  if (!validateName(lastname))
    return {
      success: false,
      err: "Last name needs to be longer than 3 characters",
    };

  if (!validatePassword(password))
    return {
      success: false,
      err: "Password must be longer than 5 characters",
    };

  if (!validatePasswordMatch(password, password2))
    return {
      success: false,
      err: "Passwords do not match",
    };
  return {
    success: true,
  };
};

const validateAccountLogin = ({ email, password }: Partial<UserLoginModel>) => {
  if (!validateEmail(email))
    return {
      success: false,
      err: "Please enter a valid email address",
    };

  if (!validatePassword(password))
    return {
      success: false,
      err: "Password must be longer than 5 characters",
    };

  return {
    success: true,
  };
};

export { validateAccountCreate, validateAccountLogin, validatePassword };
