import { useEffect } from "react";
import {
  IUnpaidFees,
  IUnpaidFeesEvents,
  IUnpaidFeesMatch,
} from "rest/payments";
import { Link } from "react-router-dom";
import EventDate from "components/EventDate";
import style from "./style.module.scss";
import { parsePrice } from "utils/parsePrice";
import List from "components/List";
import Information from "components/Information";
import API from "rest/api";
import Button from "components/Button";

type RefetchProps = {
  refetchUnpaidFees: () => void;
};

type Props = IUnpaidFeesEvents & RefetchProps;

const EventItem = ({
  id,
  name,
  price,
  start,
  type,
  user_event_id,
  refetchUnpaidFees,
}: Props) => {
  const canCancel = new Date(parseInt(start)).getTime() > Date.now();
  const text = "Remove";

  const { mutate, isSuccess } = API.events.usePatchUserEvent(id);

  useEffect(() => {
    if (isSuccess) {
      refetchUnpaidFees();
    }
  }, [isSuccess, refetchUnpaidFees]);

  const remove = () => {
    mutate({
      requestBody: {
        enabled: false,
      },
      userEventId: user_event_id,
    });
  };

  return (
    <>
      <td>
        <Link to={`/event/${id}`}>{name}</Link>
      </td>
      <td>{parsePrice(price)}</td>
      <td>
        {canCancel && (
          <Button text={text} handleClick={remove} extraClasses="text-xs" />
        )}
      </td>
      <td>
        <EventDate time={start} />
      </td>
    </>
  );
};

const MatchItem = ({
  player_1,
  player_2,
  player_1_firstname,
  player_2_firstname,
  match_date,
}: IUnpaidFeesMatch) => {
  return (
    <>
      <td>
        <Link to={`/profile/${player_1}`}>{player_1_firstname}</Link>
      </td>
      <td>
        <Link to={`/profile/${player_2}`}>{player_2_firstname}</Link>
      </td>
      <td>{parsePrice(1000)}</td>
      <td>
        <EventDate time={match_date} />
      </td>
    </>
  );
};

const UnpaidItems = ({
  events,
  matches,
  total,
  refetchUnpaidFees,
}: Pick<
  IUnpaidFees,
  "events" | "matches" | "total" | "originalTotal" | "discount"
> &
  RefetchProps) => {
  const eventsBody = events.map((item) => [
    <EventItem {...item} refetchUnpaidFees={refetchUnpaidFees} key={item.id} />,
  ]);

  const matchesBody = matches.map((item) => [
    <MatchItem {...item} key={item.id} />,
  ]);

  return (
    <>
      {events.length !== 0 && (
        <List
          title={"Events"}
          headers={["Name", "Price", "", "Date"]}
          body={eventsBody}
          columnsInBuilt
        />
      )}

      {matches.length !== 0 && (
        <List
          title={"Matches"}
          headers={["Player 1", "Player 2", "Price", "Date"]}
          body={matchesBody}
          columnsInBuilt
        />
      )}

      <Information styles={style.unpaidItems}>
        <h2>Total : {parsePrice(total)}</h2>
      </Information>
    </>
  );
};

export default UnpaidItems;
