import Information from "components/Information";

import style from "./style.module.scss";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <Information styles={style.main}>
        <h1>Welcome to North Manly Squash Club</h1>
        <img
          src={"assets/club-photo.jpeg"}
          alt={"Some of the Squash club members"}
        />
      </Information>

      <Information styles={style.main}>
        <h4>
          Play socially. All skill levels welcome! Sign up{" "}
          <Link to={"/social"}>here</Link>
        </h4>
      </Information>

      <Information styles={style.main}>
        <h4>
          Book a court{" "}
          <a
            href={
              "https://www.tennisvenues.com.au/booking/warringah-recreation-centre-squash"
            }
            target={"_blank"}
            rel={"noreferrer"}
          >
            here
          </a>
        </h4>
      </Information>
    </>
  );
};

export default Home;
