import { getAccessToken } from "rest/common";

import {
  CreateEventModel,
  EventModel,
  EventService,
  GetEventsResponseModel,
  PatchEventModel,
  UserEventPatchModel,
  UserWithoutPasswordModel,
} from "north-manly-squash-api";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export type EventQueryParams = {
  eventType?:
    | "DEFAULT"
    | "SOCIAL_BEGINNER"
    | "SOCIAL_INTERMEDIATE"
    | "SOCIAL_ADVANCED"
    | "TOURNAMENT_TIMED"
    | "TOURNAMENT_WEEKEND"
    | "PENNANT";
  startEpoch?: number;
  endEpoch?: number;
  limit?: number;
  offset?: number;
};

const api = {
  useGetEvent: (eventId: number) => {
    return useQuery<EventModel>(["event-get", eventId], () =>
      EventService.getEventById({ id: eventId })
    );
  },

  patchEvent: async (requestBody: PatchEventModel, eventId: number) => {
    return await EventService.patchEventById({
      id: eventId,
      requestBody,
      xSquashAuth: getAccessToken(),
    });
  },

  createEvent: async (requestBody: CreateEventModel) => {
    return await EventService.postEvent({
      requestBody,
      xSquashAuth: getAccessToken(),
    });
  },

  useGetEvents: (requestBody: EventQueryParams = {}) =>
    useQuery<GetEventsResponseModel>(["events-get", requestBody], () =>
      EventService.getEvents(requestBody)
    ),
  useCreateUserEvent: () => {
    const queryClient = useQueryClient();

    return useMutation(
      ({
        eventId,
        user,
        adminManualAdd = false,
      }: {
        eventId: number;
        user: UserWithoutPasswordModel;
        adminManualAdd?: boolean;
      }) =>
        EventService.postEventById({
          id: eventId,
          xSquashAuth: getAccessToken(),
          requestBody: {
            id: adminManualAdd ? user.id : undefined,
          },
        }),
      {
        onMutate: async (mutation) => {
          const key = ["event-get", mutation.eventId];
          await queryClient.cancelQueries(key);
          const previous = queryClient.getQueryData(key);

          // Optimistically update to the new value
          queryClient.setQueryData<EventModel>(key, (event) => {
            if (!event) return;
            return {
              ...event,
              participants: [
                ...(event?.participants ?? []),
                {
                  user: mutation.user,
                  user_event: {
                    id: -1, // until server gives us correct id
                    user_id: mutation.user.id,
                    event_id: mutation.eventId,
                    registered: new Date().toISOString(),
                    paid: false,
                    enabled: true,
                  },
                },
              ],
            };
          });

          // Return a context object with the snapshotted value
          return { previous };
        },
        onError: (err, mutation, context) => {
          const key = ["event-get", mutation.eventId];
          queryClient.setQueryData(key, context?.previous);
        },
        // Always refetch after error or success:
        onSettled: (data, err, mutation) => {
          const key = ["event-get", mutation.eventId];
          queryClient.invalidateQueries(key);
        },
      }
    );
  },

  usePatchUserEvent: (eventID: number) => {
    const queryClient = useQueryClient();
    return useMutation(
      ({
        requestBody,
        userEventId,
      }: {
        requestBody: UserEventPatchModel;
        userEventId: number;
      }) =>
        EventService.patchUserEvent({
          id: userEventId,
          requestBody,
          xSquashAuth: getAccessToken(),
        }),
      {
        onMutate: async (mutation) => {
          const key = ["event-get", eventID];
          await queryClient.cancelQueries(key);
          const previous = queryClient.getQueryData(key);

          // Optimistically update to the new value
          queryClient.setQueryData<EventModel>(key, (event) => {
            if (!event || !event.participants) return;
            const newParticipants = [];
            for (const participant of event.participants) {
              if (participant.user_event.id === mutation.userEventId) {
                newParticipants.push({
                  ...participant,
                  user_event: {
                    ...participant.user_event,
                    ...mutation.requestBody,
                  },
                });
              } else {
                newParticipants.push(participant);
              }
            }

            return {
              ...event,
              participants: newParticipants,
            };
          });

          // Return a context object with the snapshotted value
          return { previous };
        },
        onError: (err, mutation, context) => {
          const key = ["event-get", eventID];
          queryClient.setQueryData(key, context?.previous);
        },
        // Always refetch after error or success:
        onSettled: (data, err, mutation) => {
          const key = ["event-get", eventID];
          queryClient.invalidateQueries(key);
        },
      }
    );
  },
};

export default api;
