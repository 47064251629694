import AnalyticsProvider from "contexts/AnalyticsContext";
import QueryProvider from "contexts/QueryProvider";
import Routes from "routes";
import UserProvider from "./UserContext";
import { BrowserRouter } from "react-router-dom";
const Contexts = () => (
  <QueryProvider>
    <UserProvider>
      <BrowserRouter>
        <AnalyticsProvider>
          <Routes />
        </AnalyticsProvider>
      </BrowserRouter>
    </UserProvider>
  </QueryProvider>
);

export default Contexts;
