import React, { createContext, useContext, useEffect, useState } from "react";

import { Actions, Categories } from "analytics/events";
import GA from "react-ga4";
import { useLocation } from "react-router-dom";
import { UserContext } from "contexts/UserContext";

type AnalyticsContextType = {
  setEvent: (action: Actions, category: Categories) => void;
  setUser: (userId: number) => void;
};

const AnalyticsContext = createContext<AnalyticsContextType>({
  setEvent: () => {},
  setUser: () => {},
});

const AnalyticsProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const { user, isLoading } = useContext(UserContext);

  const setEvent = (action: Actions, category: Categories) => {
    GA.event({ action, category });
  };

  const setUser = (userId: number) => {
    GA.set({ userId: userId.toString() });
  };

  useEffect(() => {
    // no need to send a page view the first time because gtag already does it for us
    if (!isLoading && !initialized) {
      const gaOptions = {
        userId: user?.id ? user.id.toString() : undefined,
      };
      GA.initialize("G-WN1BZS0GXK", {
        gaOptions: {
          ...gaOptions,
        },
      });
      setInitialized(true);
    }
  }, [initialized, isLoading, user?.id]);

  useEffect(() => {
    if (initialized) {
      GA.send({
        hitType: "pageview",
        path: location.pathname + location.search,
      });
    }
  }, [initialized, location]);

  return (
    <AnalyticsContext.Provider value={{ setEvent, setUser }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
export const useAnalyticsConext = () => useContext(AnalyticsContext);
export default AnalyticsProvider;
