import React from "react";
import AdminControl from "components/AdminControl";

import { Link } from "react-router-dom";
import { UserWithoutPasswordModel } from "north-manly-squash-api";

const ProfileAdminInfo = (user: UserWithoutPasswordModel) => {
  return (
    <>
      <h5>{user.email}</h5>
      <h5>{user.phone}</h5>
      {user.sporty_hq_url && (
        <h5>
          SportyHQ rating{" "}
          <a
            href={user.sporty_hq_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {user.sporty_hq_approved
              ? user.sporty_hq_rating ?? "No rating yet"
              : user.sporty_hq_url}
          </a>
        </h5>
      )}

      <h5>
        <Link to={`/settle-up/${user.id}`}>Debts</Link>
      </h5>
    </>
  );
};

export default AdminControl(ProfileAdminInfo);
