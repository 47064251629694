import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Base from "components/Base";
import Home from "pages/Home";
import Social from "pages/Social";
import Event from "pages/Event";

import Shop from "pages/Shop";
import Faq from "pages/FAQ";
import LoginError from "pages/LoginError";
import Login from "pages/Login";
import CreateUser from "pages/CreateUser";
import Profile from "pages/Profile";
import OldAdmin from "pages/Admin";
import Admin from "pages/Admin2";
import ResetPassword from "pages/ResetPassword";
import ForgotPassword from "pages/ForgotPassword";
import Payment from "pages/Payment";
import PaymentComplete from "pages/Payment/PaymentComplete";

import Beginners from "pages/Beginners";
import Breadcrumbs from "components/Breadcrumbs";
import SaveSquash from "pages/SaveSquash";
import { Gear } from "pages/Gear";
import { Logout } from "pages/Login/logout";
import { BookingConfirm } from "pages/Booking/confirm";
import { BookingCreate } from "pages/Booking/create";
import Feedback from "pages/Feedback/feedback";

const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/gear">
      <Gear />
    </Route>

    <Route exact path="/feedback">
      <Feedback />
    </Route>
    <Base>
      <Breadcrumbs />
      <Route path="/admin">
        <>
          <Admin />
          <OldAdmin />
        </>
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/social">
        <Social />
      </Route>
      <Route path="/event/:eventid">
        <Event />
      </Route>
      <Route exact path="/competition">
        <Redirect to={"/competition/ladder/1"} />
      </Route>

      <Route path="/competition/ladder/:ladderid">
        <Redirect to="/" />
      </Route>

      <Route path="/faq">
        <Faq />
      </Route>
      <Route path="/shop">
        <Shop />
      </Route>

      <Route path="/profile/:userid">
        <Profile />
      </Route>

      <Route path="/login">
        <Login />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>

      <Route path="/create">
        <CreateUser />
      </Route>

      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>

      <Route path="/loginerror">
        <LoginError />
      </Route>

      <Route path="/reset-password">
        <ResetPassword />
      </Route>

      <Route exact path="/settle-up">
        <Payment />
      </Route>

      <Route exact path="/settle-up/:userid">
        <Payment />
      </Route>

      <Route path="/payment-check">
        <PaymentComplete />
      </Route>

      <Route path="/beginners">
        <Beginners />
      </Route>

      <Route path="/coaching">
        <Redirect to="/" />
      </Route>

      <Route exact path="/bookings">
        <BookingCreate />
      </Route>
      <Route path="/bookings/confirm/:id">
        <BookingConfirm />
      </Route>

      <Route
        path="/rate-us"
        component={() => {
          window.location.href = "https://g.page/r/CZdIFxlCAlLcEBM/review";
          return null;
        }}
      />

      <Route path="/save">
        <SaveSquash />
      </Route>
    </Base>
  </Switch>
);

export default Routes;
