import React from "react";
import style from "./style.module.scss";
interface IProps {
  label: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  name: string;
  type?: string;
  checked?: boolean;
  placeholder?: string;
}

const Input = ({
  type = "text",
  name,
  label,
  handleChange,
  value = "",
  checked,
  placeholder = "",
}: IProps) => {
  return (
    <div className={style.parent}>
      <label>{label}</label>
      <input
        name={name}
        type={type}
        onChange={handleChange}
        checked={checked}
        placeholder={placeholder}
        value={value}
      />
    </div>
  );
};

export default Input;
