import React from "react";
import UserRow from "components/UserRow";
import List from "components/List";

import API from "rest/api";
import { ReminderType } from "rest/notifications";
import Information from "components/Information";
import Button from "components/Button";

import style from "./style.module.scss";
import { MatchWithUserDetailsModel } from "north-manly-squash-api";

const ResultRow = ({
  player_1,
  player_1_firstname,
  player_2,
  player_2_firstname,
}: MatchWithUserDetailsModel) => {
  const handleSubmit = () => {
    API.notifications.remindPlayers({
      player_1,
      player_2,
      reminderType: ReminderType["pending-matches"],
    });
  };

  return (
    <>
      <td>
        <UserRow id={player_1} name={player_1_firstname} />
      </td>
      <td>
        <UserRow id={player_2} name={player_2_firstname} />
      </td>

      <td>
        <Button text="Remind" handleClick={handleSubmit} />
      </td>
    </>
  );
};

const PendingAccepted = () => {
  const { data, isLoading, isError } = API.ladder.useGetMatches({
    state: "PENDING_USER",
  });

  if (isLoading || isError) return null;
  const { matches } = data;

  if (matches.length === 0)
    return (
      <Information styles={style.gap}>
        <h3>There are no pending matches.</h3>
      </Information>
    );

  const body = matches.map((match) => [<ResultRow {...match} />]);

  return (
    <List
      title="Pending matches"
      headers={["Challenger", "Opponent", ""]}
      body={body}
      columnsInBuilt
    />
  );
};

export default PendingAccepted;
