import React, { useState, useContext } from "react";

import { useHistory, Link } from "react-router-dom";

import { UserContext } from "contexts/UserContext";
import { validateEmail } from "utils/validation";
import API from "rest/api";
import Information from "components/Information";
import Input from "components/Input";
import Button from "components/Button";
import style from "pages/CreateUser/style.module.scss";
import toast from "react-hot-toast";

type Form = {
  email?: string;
};

const ForgotPassword = () => {
  const history = useHistory();

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const redirect = params.get("redirect");
  const path = redirect ? redirect : "/";
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<Form>({});

  if (user?.id) {
    history.push(path);
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const valid = validateEmail(form?.email);

    if (!valid) {
      toast.error("Invalid email");
      return;
    }

    setLoading(true);
    API.users.userGenerateReset({ email: form.email! }).finally(() => {
      setLoading(false);
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  if (loading) {
    return (
      <Information>
        <h1>Sending a reset link to your phone...</h1>
      </Information>
    );
  }

  return (
    <Information>
      <h1>Reset password</h1>

      <form>
        <div className={style.inputWrapper}>
          <Input
            label="Email address"
            value={form["email"]}
            handleChange={handleChange}
            name="email"
            type="email"
          />
        </div>
        <Button text="Send reset link to my phone" handleClick={handleSubmit} />
      </form>

      <Link to="/create" className={style.login}>
        Need to make an account? Create one here here
      </Link>

      <Link to="/login" className={style.login}>
        Already have an account? Log in here
      </Link>
    </Information>
  );
};

export default ForgotPassword;
