const parsePrice = (price: number) => {
  if (price === 0) {
    return "FREE";
  }
  const priceStr = price.toString();

  const dollars = priceStr.slice(0, priceStr.length - 2);
  const cents = priceStr.slice(priceStr.length - 2, priceStr.length);
  return `$${dollars}.${cents}`;
};

export { parsePrice };
