import { useMutation } from "@tanstack/react-query";
import { BookingService } from "north-manly-squash-api";
import { getAccessToken } from "rest/common";

const api = {
  useCreateBooking: () => {
    return useMutation(({ epoch }: { epoch: number }) =>
      BookingService.postBooking({
        xSquashAuth: getAccessToken(),
        requestBody: {
          epoch,
        },
      })
    );
  },

  useConfirmBooking: () => {
    return useMutation((bookingID: string) =>
      BookingService.postBookingConfirm({
        id: bookingID,
      })
    );
  },
};
export default api;
