import { Link, useParams } from "react-router-dom";

import Information from "components/Information";
import API from "rest/api";

import ProfileAdminInfo from "components/ProfileAdminInfo";
import SocialAd from "components/Ads/social";

interface ParamTypes {
  userid?: string;
}

const Profile = () => {
  const { userid } = useParams<ParamTypes>();

  if (!userid) return null;

  const { data: user, isLoading } = API.users.useGetUser(parseInt(userid));

  const { data: pastEvents } = API.users.useGetPastEvents(parseInt(userid));
  if (isLoading || !user) return null;

  return (
    <>
      <Information>
        <h1>{user.firstname}'s Profile</h1>
        {user.sporty_hq_approved && (
          <h5>
            SportyHQ rating{" "}
            <a
              href={user.sporty_hq_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {user.sporty_hq_rating}
            </a>
          </h5>
        )}
        <ProfileAdminInfo {...user} />
      </Information>
      <SocialAd />
      {/* <SubmitResults id={user.id} />
      <Matches isChallenge={true} player_id={user.id} />
      <Matches isChallenge={false} player_id={user.id} /> */}

      {pastEvents && pastEvents.events.length > 0 && (
        <Information gap>
          <ol>
            {pastEvents.events.map((event) => (
              <li>
                <Link to={`/event/${event.id}`}>{event.name}</Link>
              </li>
            ))}
          </ol>
        </Information>
      )}
      <Link to="/logout">
        <Information gap styles="bg-red-700 text-white text-center">Logout</Information>
      </Link>
    </>
  );
};

export default Profile;
