import React, { useContext } from "react";
import classnames from "classnames";
import { UserContext } from "contexts/UserContext";
import style from "./style.module.scss";
import { Link } from "react-router-dom";
interface IProps {
  headerScrolled?: boolean;
}

const User = ({ headerScrolled }: IProps) => {
  const { user } = useContext(UserContext);

  return (
    <div className={style.user}>
      {user?.id ? (
        <Link
          to={`/profile/${user.id}`}
          className={classnames(
            style.book,
            style.inner,
            style.profile,
            headerScrolled && style["header-scrolled"]
          )}
        >
          {user.firstname.charAt(0)}
          {user.lastname.charAt(0)}
        </Link>
      ) : (
        <Link
          to="/login"
          className={classnames(
            style.book,
            headerScrolled && style["header-scrolled"]
          )}
        >
          LOGIN
        </Link>
      )}
    </div>
  );
};

export default User;
