import { useQuery } from "@tanstack/react-query";
import { getAccessToken } from "rest/common";
import { AiService } from "north-manly-squash-api";
const api = {
  useGetEmail: () => {
    return useQuery<{ message: string }>(["email"], () =>
      AiService.getEmail({
        xSquashAuth: getAccessToken(),
      })
    );
  },
};
export default api;
