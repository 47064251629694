import Button from "components/Button";
import Information from "components/Information";
import toast from "react-hot-toast";
import API from "rest/api";
import { ReactComponent as LoadingSpinner } from "icons/loading.svg";

const emailsText = `
Michael.Regan@northernbeaches.nsw.gov.au;
Ray.Brownlee@northernbeaches.nsw.gov.au;
David.Walton@northernbeaches.nsw.gov.au;
Bianca.Crvelin@northernbeaches.nsw.gov.au;
Stuart.Sprott@northernbeaches.nsw.gov.au;
Vincent.DeLuca@northernbeaches.nsw.gov.au;
Ruth.Robins@northernbeaches.nsw.gov.au;
Rory.Amon@northernbeaches.nsw.gov.au;
Michael.Gencher@northernbeaches.nsw.gov.au;
Miranda.Korzy@northernbeaches.nsw.gov.au;
Jose.Menano-Pires@northernbeaches.nsw.gov.au;
Candy.Bingham@northernbeaches.nsw.gov.au;
Georgia.Ryburn@northernbeaches.nsw.gov.au;
Sarah.Grattan@northernbeaches.nsw.gov.au;
Ruth.Robins@northernbeaches.nsw.gov.au;
Kristyn.Glanville@northernbeaches.nsw.gov.au;
Sue.Heins@northernbeaches.nsw.gov.au;
Rory.Amon@northernbeaches.nsw.gov.au;`;

const defaultMessage = `
Dear Councillors of Warringah Council,

I am writing on behalf of the North Manly Squash Club, which operates from the Warringah Recreation Complex (WRC). We are deeply concerned about the lack of a plan from the Council regarding the development site that has been flagged for demolition to make way for a Golf Club. The Squash Club has yet to receive confirmation of any plans to replace our facilities, and more importantly, there has been no indication of a time frame for completion.

This situation is especially alarming as Squash players have no other options to play unlike other sports such as Tennis or Futsal who share the WRC. As such, we urge the Council to provide a timeframe and a plan for the replacement of our facilities before the site is demolished.

Additionally, we believe it is feasible to fence off the Squash courts until the last possible moment so that they can still be used while the Golf Club is built. Doing so would minimize the time that our players are without courts to play on.

We understand that the Council has not had a good track record of completing projects on time, and we would like to point out two current examples of community assets that have been sitting empty for years: the North Manly Bowling Club and the Manly Council chambers. It is our hope that the Council will take this into consideration and prioritize the needs of the Squash Club members.

In conclusion, we urge the Council to provide a plan and time frame for the replacement of our facilities before any demolition takes place. We also hope that the Council will consider our proposal to fence off the Squash courts until the last possible moment to minimize the impact on our players.

Thank you for your attention to this matter.

Sincerely,

[Your Name]
`;

const SaveSquash = () => {
  const { data, isLoading } = API.ai.useGetEmail();

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        /* clipboard successfully set */
        toast.success("Copied text");
      },
      () => {
        /* clipboard write failed */
        toast.error("Could not copy text");
      }
    );
  };

  const handleCopyEmails = () => {
    handleCopy(emailsText);
  };

  const handleCopyTemplate = () => {
    handleCopy(data?.message ?? defaultMessage);
  };

  return (
    <>
      <Information gap>
        <h3>Contact these council emails</h3>
        <Button handleClick={handleCopyEmails} text="Copy emails" />
        <textarea
          style={{
            width: "100%",
            height: "150px",
            marginTop: "8px",
          }}
          readOnly
        >
          {emailsText}
        </textarea>
      </Information>

      <Information gap>
        {isLoading ? (
          <div className="space-y-2 flex flex-col items-center">
            <h3 className="m-0 text-center">Loading AI generated content...</h3>
            <div className="flex justify-center space-x-8">
              <LoadingSpinner />
              <LoadingSpinner />
              <LoadingSpinner />
            </div>

            <h4 className="text-center">This can take a minute</h4>
          </div>
        ) : (
          <>
            <h3>Email template, feel free to modify or write your own!</h3>
            <Button handleClick={handleCopyTemplate} text="Copy template" />
            <textarea
              style={{
                width: "100%",
                height: "800px",
                marginTop: "8px",
              }}
              readOnly
            >
              {data?.message ?? defaultMessage}
            </textarea>
          </>
        )}
      </Information>
    </>
  );
};

export default SaveSquash;
