import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

interface IProps {
  handleClick?: (e: any) => void;
  text: string;
  disabled?: boolean;
  type?: "link" | "submit";
  href?: string;
  internalHref?: string;
  primary?: boolean;
  extraClasses?: string;
  icon?: React.ReactNode;
}

const Button = ({
  type,
  disabled = false,
  handleClick,
  text,
  href,
  internalHref,
  primary = true,
  extraClasses,
  icon,
}: IProps) => {
  const classes = classnames(
    primary ? "bg-dark" : "bg-darkSecondary",
    "px-4 py-2 pointer border-2 border-blue text-light rounded-md flex items-center justify-center",
    "shadow-md tracking-wide transition-all duration-300",
    "hover:bg-light hover:text-dark cursor-pointer",
    "disabled:bg-gray-600 disabled:text-light disabled:cursor-not-allowed",
    extraClasses
  );
  if (type === "link") {
    if (internalHref) {
      return (
        <Link to={internalHref} className={classes}>
          <span>{text}</span>
          {icon}
        </Link>
      );
    }

    return (
      <a
        className={classes}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>{text}</span>
        {icon}
      </a>
    );
  }

  return (
    <button
      className={classes}
      disabled={disabled}
      onClick={handleClick}
      type={type}
    >
      <span>{text}</span>
      {icon}
    </button>
  );
};

export default Button;
