import { UserContext } from "contexts/UserContext";
import { EventService } from "north-manly-squash-api";
import { useContext, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { getAccessToken } from "rest/common";

import { Item } from "./item";
import { ReactComponent as SecureSVG } from "icons/secure-checkout.svg";
import classNames from "classnames";
import Header from "components/Header";
import { formatDistance } from "date-fns";

export const PENNANT_USERS = [
  1, // me
  14, // pete
  219, // pete l
  22, // scott
  82, // sid
  16, // alex D
  20, // ranj
  97, // steve
  12, // kai
  175, // rechard
];
export enum GearNames {
  Shirt = "Shirt",
  Shorts = "Shorts",
  Hat = "Hat",
  Trackpants = "Trackpants",
}

export type CartItem = Pick<
  GearConfig,
  "name" | "isFreeForPenannt" | "price" | "size"
>;

type GearConfig = {
  name: GearNames;
  price: number;
  image: string;
  isFreeForPenannt?: boolean;
  size: string;
  oneSizeFitsAll?: boolean;
};

const GearConfig: Omit<GearConfig, "size">[] = [
  {
    name: GearNames.Shirt,
    price: 33,
    image: "shirt.png",
    isFreeForPenannt: true,
  },
  {
    name: GearNames.Shorts,
    price: 33,
    image: "shorts.png",
  },
  {
    name: GearNames.Hat,
    price: 20,
    image: "hat.png",
    isFreeForPenannt: true,
    oneSizeFitsAll: true,
  },
  {
    name: GearNames.Trackpants,
    price: 65,
    image: "trackpants.png",
  },
];

export const Gear = () => {
  const [cart, setCart] = useState<CartItem[]>([]);

  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { user } = useContext(UserContext);

  const handleSubmit = async () => {
    if (!user?.id) {
      return;
    }
    setLoading(true);
    const url =
      "https://docs.google.com/forms/u/0/d/e/1FAIpQLSffcL80C7Iwv_mw4SYcoPHI4zN_NHEIyMemcO_oJUISEvIToA/formResponse";

    const shirtSize =
      cart.find(({ name }) => name === GearNames.Shirt)?.size ?? "FALSE";
    const shortsSize =
      cart.find(({ name }) => name === GearNames.Shorts)?.size ?? "FALSE";
    const hatSize =
      cart.find(({ name }) => name === GearNames.Hat)?.size ?? "FALSE";
    const trackpantsSize =
      cart.find(({ name }) => name === GearNames.Trackpants)?.size ?? "FALSE";

    try {
      const formData = new FormData();

      const userIsPennantPlayer = PENNANT_USERS.includes(user.id);

      formData.append("entry.1793300013", user.id.toString());
      formData.append("entry.176465782", user.firstname);
      formData.append("entry.943198522", user.lastname);
      formData.append("entry.898580550", shirtSize);
      formData.append("entry.1712812056", shortsSize);
      formData.append("entry.914141601", hatSize);
      formData.append("entry.1403337993", trackpantsSize);
      formData.append(
        "entry.1865302580",
        userIsPennantPlayer ? "TRUE" : "FALSE"
      );

      fetch(url, { method: "post", body: formData });

      const promises = [];

      if (shirtSize !== "FALSE") {
        if (userIsPennantPlayer) {
          // free pennant shirt
          promises.push(
            EventService.postEventById({
              id: 575,
              xSquashAuth: getAccessToken(),
            })
          );
        } else {
          // normal shirt
          promises.push(
            EventService.postEventById({
              id: 571,
              xSquashAuth: getAccessToken(),
            })
          );
        }
      }

      if (shortsSize !== "FALSE") {
        promises.push(
          EventService.postEventById({
            id: 572,
            xSquashAuth: getAccessToken(),
          })
        );
      }

      if (hatSize !== "FALSE") {
        if (userIsPennantPlayer) {
          promises.push(
            EventService.postEventById({
              id: 577,
              xSquashAuth: getAccessToken(),
            })
          );
        } else {
          promises.push(
            EventService.postEventById({
              id: 574,
              xSquashAuth: getAccessToken(),
            })
          );
        }
      }

      if (trackpantsSize !== "FALSE") {
        promises.push(
          EventService.postEventById({
            id: 573,
            xSquashAuth: getAccessToken(),
          })
        );
      }
      await Promise.all(promises);
      history.push("/settle-up");
    } catch (err) {
      const formData = new FormData();
      formData.append("entry.1793300013", `Failed submission, ${user.id}`);
      fetch(url, { method: "post", body: formData });
      return;
    }
  };

  const addToCart = (item: CartItem) => {
    setCart([...cart, item]);
  };

  const removeFromCart = (item: CartItem) => {
    toast.success("Removed from cart", { duration: 1000 });

    setCart(cart.filter((i) => i.name !== item.name));
  };

  const handleClick = (item: CartItem) => {
    if (cart.find(({ name }) => name === item.name)) {
      removeFromCart(item);
    } else {
      addToCart(item);
    }
  };

  const total = cart.reduce((acc, { price, isFreeForPenannt }) => {
    if (isFreeForPenannt) return acc;
    return acc + price;
  }, 0);

  const buyString = `Buy ${cart.length} ${
    cart.length === 1 ? "item" : "items"
  } now ($${total})`;

  const daysLeft = formatDistance(new Date(1676379600000), new Date());

  return (
    <>
      <Header />
      <div className="fixed top-0 left-0 right-0 bottom-0 bg-[url('/assets/bg-3.png')] bg-no-repeat bg-center bg-cover z-0"></div>
      {cart.length > 0 && (
        <button
          onClick={handleSubmit}
          className={classNames(
            "fixed bottom-0 z-20",
            "flex justify-center items-center space-x-2 border-2 p-4 underline text-lg",
            "w-full border-gray-900 border-4 text-gray-900 bg-[#fad432] disabled:bg-gray-600 disabled:text-white"
          )}
          disabled={loading}
        >
          <span>{loading ? "Loading..." : buyString}</span>
          <SecureSVG className="w-8" />
        </button>
      )}
      <section className="relative flex flex-col items-center text-slate-50">
        <div className="space-y-4 my-12 mb-40 w-full flex flex-col items-center max-w-[400px]">
          <div className="flex flex-col items-center rounded-lg  p-1 mx-2 w-[calc(100%-40px)]  text-gray-700  drop-shadow-lg	bg-slate-50 opacity-90">
            <div className="relative z-10 space-y-4 mb-4 w-full">
              <h2 className="text-2xl bg-gray-800 p-4 rounded-lg text-center text-white border-gray-700 border-2 m-0">
                2023 Gear
              </h2>
              <div className="px-2 space-y-4">
                <p className="text-center">The kits have now been ordered.</p>
                <p className="text-center">
                  If you purchased items they will be delivered to you soon by
                  Gill.
                </p>
                <p className="text-center">
                  Kits designed by{" "}
                  <a href="https://au.paladin.sport/#" target="_blank">
                    Paladin Sport
                  </a>
                </p>
                <p className="text-center">
                  Sponsored by{" "}
                  <a href="https://grapple.com.au/" target="_blank">
                    Grapple
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <Toaster position="bottom-center" />
      </section>
    </>
  );
};
