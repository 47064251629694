import React, { createContext } from "react";
import API from "rest/api";
import { UserWithoutPasswordModel } from "north-manly-squash-api";

type UserContextType = {
  user?: UserWithoutPasswordModel;
  isLoading: boolean;
  logout: () => void;
};

const UserContext = createContext<UserContextType>({
  user: undefined,
  isLoading: true,
  logout: () => {},
});

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLoading, data } = API.users.useMe();

  API.users.useRefreshMyUser(data);

  const logout = () => {
    window.localStorage.removeItem("token");
    window.location.href = "/";
  };

  return (
    <UserContext.Provider
      value={{ user: data, isLoading, logout } as UserContextType}
    >
      {children}
    </UserContext.Provider>
  );
};
export { UserContext };
export default UserProvider;
