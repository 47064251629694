import { UserContext } from "contexts/UserContext";
import { useContext, useEffect } from "react";

export const Logout: React.FC = () => {
  const { logout } = useContext(UserContext);

  useEffect(() => {
    logout();
  }, []);
  return null;
};
