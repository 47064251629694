import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { BASE_URL } from "rest/common";

type FormPost = {
  url: string;
  form: [string, any][];
};

const api = {
  useCreateForm: () => {
    return useMutation((data: FormPost) => {
      return axios.post(`${BASE_URL}/forms`, data);
    });
  },
};
export default api;
