import React from "react";
import Button from "components/Button";
import AdminControl from "components/AdminControl";
import API from "rest/api";
import { useParams } from "react-router-dom";

import style from "./style.module.scss";

const MarkAsPaidAdmin = () => {
  const { userid } = useParams<{ userid: string }>();

  const handleClick = () => {
    API.payments.settleDebtsAdmin(userid);
  };

  return (
    <Button
      text={"Mark as paid"}
      handleClick={handleClick}
      extraClasses={style.adminButton}
    />
  );
};

export default AdminControl(MarkAsPaidAdmin);
