import Information from "components/Information";
import React, { useContext, useEffect } from "react";
import style from "./style.module.scss";
import Button from "components/Button";
import API from "rest/api";
import { UserContext } from "contexts/UserContext";
import { useHistory } from "react-router-dom";

import { useAnalyticsConext } from "contexts/AnalyticsContext";
import { Actions, Categories } from "analytics/events";

interface IProps {
  registerCTA: string;
  eventId: number;
  isFull: boolean;
  isOpen: boolean;
  isComplete: boolean;
  alreadyRegistered: boolean;
  isFree: boolean;
}

const Register = ({
  registerCTA,
  eventId,
  isFull,
  isOpen,
  isComplete,
  alreadyRegistered,
  isFree,
}: IProps) => {
  const history = useHistory();
  const { user } = useContext(UserContext);

  const { mutate, isSuccess: signedUpToEvent } =
    API.events.useCreateUserEvent();
  const { setEvent } = useAnalyticsConext();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user)
      return history.push(`/create?redirect=${window.location.pathname}`);
    mutate({ eventId, user });
    setEvent(Actions.social_signup, Categories.button);
  };

  useEffect(() => {
    if (signedUpToEvent && !isFree) {
      history.push("/settle-up");
    }
  }, [history, signedUpToEvent, isFree]);

  if (alreadyRegistered) return null;

  if (isFull) {
    return (
      <>
        <Information>This event is full</Information>
      </>
    );
  }

  if (!isOpen) {
    return <Information>This event is not yet open</Information>;
  }

  if (isComplete) {
    return <Information>This event has finished</Information>;
  }

  return (
    <>
      <form className={style.register} onSubmit={handleSubmit}>
        <Information styles={style["register-information"]}>
          <Button
            type="submit"
            text={registerCTA}
            extraClasses={style.button}
          />
        </Information>
      </form>
    </>
  );
};

export default Register;
