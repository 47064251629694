import Button from "components/Button";
import React from "react";
import ReactDOM from "react-dom";
import style from "./style.module.scss";
const modalRoot = document.getElementById("modal") as HTMLElement;

interface IProps {
  children: React.ReactNode;
  setOpen: (open: boolean) => void;
}

const Modal = (props: IProps) => {
  const handleClose = () => {
    props.setOpen(false);
  };

  return ReactDOM.createPortal(
    <div className={style.modalOuter}>
      <div className={style.modal}>
        <button
          className={style.close}
          onClick={handleClose}
          title="Close"
        ></button>
        {props.children}
      </div>
    </div>,
    modalRoot
  );
};

type IModalActionsProps = {
  onClickPrimary: () => void;
  primaryText: string;
  onClickSecondary?: () => void;
  secondaryText?: string;
  primaryDisabled?: boolean;
  secondaryDisabled?: boolean;
};
const ModalActions = ({
  onClickPrimary,
  onClickSecondary,
  primaryText,
  secondaryText,
  primaryDisabled,
  secondaryDisabled,
}: IModalActionsProps) => {
  return (
    <div className={style.modalActions}>
      {onClickSecondary && secondaryText && (
        <Button
          primary={false}
          text={secondaryText}
          handleClick={onClickSecondary}
          disabled={secondaryDisabled}
        />
      )}
      <Button
        primary={true}
        text={primaryText}
        handleClick={onClickPrimary}
        disabled={primaryDisabled}
      />
    </div>
  );
};

export { ModalActions };

export default Modal;
