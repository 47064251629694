import { useCallback } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import API from "rest/api";
import * as stripeJs from "@stripe/stripe-js";
import "./index.css";
import CheckoutForm from "./CheckoutForm";
import UnpaidItems from "./UnpaidItems";
import Information from "../../components/Information";
import { useParams } from "react-router-dom";
import MarkAsPaidAdmin from "./MarkAsPaidAdmin";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISH_KEY!);

const Payment = () => {
  const { userid } = useParams<{ userid: string }>();

  const { data, isLoading, refetch, isFetching } =
    API.payments.useGetUnpaidFees(userid);

  const itemBeenRemoved = useCallback(() => {
    refetch();
  }, [refetch]);

  if (isLoading || isFetching)
    return (
      <Information>
        <h2>Loading unpaid items...</h2>
      </Information>
    );

  if (!data)
    return (
      <Information>
        <h2>Something went wrong loading your balance.</h2>
      </Information>
    );

  const { paymentIntentId, events, matches, total, originalTotal, discount } =
    data;

  if (events.length === 0 && matches.length === 0) {
    return (
      <Information>
        <h2>You have no outstanding debts!</h2>
      </Information>
    );
  }

  if (!paymentIntentId) {
    // admin usage only
    return (
      <>
        <UnpaidItems
          events={events}
          matches={matches}
          total={total}
          originalTotal={originalTotal}
          discount={discount}
          refetchUnpaidFees={itemBeenRemoved}
        />
        <MarkAsPaidAdmin />
      </>
    );
  }

  const options = {
    // passing the client secret obtained from the server
    clientSecret: paymentIntentId,
    appearance: {
      theme: "stripe",
    },
  } as stripeJs.StripeElementsOptions;

  return (
    <Elements stripe={stripePromise} options={options}>
      <UnpaidItems
        events={events}
        matches={matches}
        total={total}
        originalTotal={originalTotal}
        discount={discount}
        refetchUnpaidFees={itemBeenRemoved}
      />
      {total > 0 && <CheckoutForm />}
    </Elements>
  );
};

export default Payment;
