import Information from "components/Information";
import React, { useContext, useEffect, useState } from "react";
import API from "rest/api";
import { useHistory, useParams } from "react-router-dom";
import { UserContext } from "contexts/UserContext";
import Button from "components/Button";
import { addDays, format, isAfter } from "date-fns";

const validBookingDate = addDays(new Date(), 2);
const initialDate = format(addDays(validBookingDate, 1), "yyyy-MM-dd");
const initialTime = format(new Date(), "HH:mm");

export const BookingCreate: React.FC = () => {
  const { mutate, isLoading, isError, isSuccess } =
    API.booking.useCreateBooking();
  const { user } = useContext(UserContext);
  const history = useHistory();

  const [date, setDate] = useState<string>(initialDate);
  const [time, setTime] = useState<string>(initialTime);

  const handleDateChange = (data: React.ChangeEvent<HTMLInputElement>) => {
    setDate(data.target.value);
  };

  const handleTimeChange = (data: React.ChangeEvent<HTMLInputElement>) => {
    setTime(data.target.value);
  };

  const isValidBookingDate = isAfter(new Date(date), validBookingDate);

  const createBooking = () => {
    if (!user) {
      history.push("/login?redirect=/bookings");
      return;
    }
    if (!date || !time) {
      return;
    }
    const bookingDate = new Date(`${date} ${time}`);
    mutate({ epoch: bookingDate.getTime() });
  };

  if (isError) {
    return (
      <Information>
        <h2>
          Something went wrong, please contact northmanlysquashclub@gmail.com
        </h2>
      </Information>
    );
  }

  if (isLoading) {
    return (
      <Information>
        <h2>Loading...</h2>
      </Information>
    );
  }

  if (isSuccess) {
    return (
      <Information>
        <h2>Booking requested successfully.</h2>
        <h5>
          You will get a confirmation text when the booking has been actioned.
        </h5>
      </Information>
    );
  }

  return (
    <Information>
      <h2>Select a date and time to request a booking</h2>
      <p>
        Note that this is a manual process, and the courts might not be free by
        the time we process this request.
      </p>
      <p className="italic underline">
        If your booking is confirmed you will be notified via text, and charged
        $10
      </p>
      <div className="my-4 flex flex-col space-y-4">
        <div className="flex flex-col space-y-2">
          <label htmlFor="datepicker" className="italic text-gray-600">
            Date
          </label>
          <input
            id="datepicker"
            type="date"
            value={date}
            onChange={handleDateChange}
          />
        </div>
        <div className="flex flex-col space-y-2">
          <label htmlFor="timepicker" className="italic text-gray-600">
            Time
          </label>
          <input
            id="timepicker"
            type="time"
            value={time}
            onChange={handleTimeChange}
          />
        </div>

        <Button
          handleClick={createBooking}
          text="Request booking"
          disabled={!isValidBookingDate}
        />
      </div>
    </Information>
  );
};
