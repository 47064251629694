import React, { useEffect, useState } from "react";
import API from "../../rest/api";
import style from "./style.module.scss";
import { Link } from "react-router-dom";
import Information from "components/Information";

const PaymentComplete = () => {
  const [message, setMessage] = useState<string | null | undefined>(null);
  const [loading, setLoading] = useState(true);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent"
  );

  useEffect(() => {
    if (!clientSecret) {
      return;
    }
    API.payments
      .settleDebts(clientSecret)
      .then((res) => {
        if (res.success) {
          setMessage(res.message);
          setPaymentSuccess(res.paymentSuccess);
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch(() => {
        setMessage("Something went wrong.");
      })
      .finally(() => setLoading(false));
  }, [setMessage, setLoading, clientSecret]);

  if (!clientSecret) {
    return (
      <Information styles={style.unpaidItems}>
        <h2>No Payment ID found</h2>
        <Link to="/settle-up">Try paying again</Link>
      </Information>
    );
  }

  if (loading) {
    return (
      <Information styles={style.unpaidItems}>
        <h2>Loading payment status...</h2>
      </Information>
    );
  }

  return (
    <Information styles={style.unpaidItems}>
      <h2>{message}</h2>
      {paymentSuccess && <Link to="/feedback">Do you have feedback or suggestions for us?</Link>}
      {!paymentSuccess && <Link to="/settle-up">Try paying again</Link>}
    </Information>
  );
};

export default PaymentComplete;
