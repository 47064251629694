import { ApiError } from "north-manly-squash-api";

//export const BASE_URL = "https://north-manly-squash.herokuapp.com/api";
export const BASE_URL = `${import.meta.env.VITE_API_URL}/api`;
export interface IJsonResponse<T> {
  data: T;
}

export interface IResultResponse {
  success: boolean;
  err?: string;
}

export const commonAxiosConfig = {
  headers: { "Content-Type": "application/json" },
};

export const getAccessToken = () => {
  const token = window.localStorage.getItem("token");
  return `Bearer ${token}`;
};

export const handleErrors = async (api: () => Promise<unknown>) => {
  try {
    return api();
  } catch (err) {
    if (err instanceof ApiError) {
      console.log(err);
    }
  }
};
