import React, { useState } from "react";
import Button from "components/Button";
import API from "rest/api";
import AdminControl from "components/AdminControl";
import style from "./style.module.scss";

import UserSearch from "components/UserSearch";
import { UserWithoutPasswordModel } from "north-manly-squash-api";

const ManualRegistration = ({ event_id }: { event_id: number }) => {
  const [user, setUser] = useState<UserWithoutPasswordModel>();

  const { mutate } = API.events.useCreateUserEvent();

  const handleClick = () => {
    if (!user) return;
    mutate({ eventId: event_id, user, adminManualAdd: true });
  };

  const handleSelect = (user: UserWithoutPasswordModel) => {
    setUser(user);
  };

  return (
    <div className={style.area}>
      <UserSearch onSelect={handleSelect} />
      <Button text="Register user" handleClick={handleClick} />
    </div>
  );
};

export default AdminControl(ManualRegistration);
