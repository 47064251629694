import {
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ApiError } from "north-manly-squash-api";
import React from "react";
import toast from "react-hot-toast";

const mutationCache = new MutationCache({
  onError: (err) => {
    if (err instanceof ApiError) {
      if (err.status === 401) {
        // unauthenticated
        window.location.href = `${window.location.origin}/login?redirect=${window.location.pathname}`;
        return;
      }

      if (err.status === 403) {
        // duplicate post
        toast.error(`Denied: ${err.body}`);
        return;
      }

      if (err.status === 409) {
        // duplicate post
        toast.error(`Duplicate: ${err.body}`);
        return;
      }

      if (err.status === 404) {
        toast.error(`Not found: ${err.body}`);
        return;
      }

      toast.error("Unknown error occurred");
    }
  },
  onSuccess: () => {
    // show flag
    toast.success("Success");
  },
});

const queryClient = new QueryClient({
  mutationCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      onError: (err) => {
        if (err instanceof ApiError) {
          if (err.status === 401) {
            // unauthenticated
            window.location.href = `${window.location.origin}/login?redirect=${window.location.pathname}`;
          }
        }
      },
    },
  },
});

const QueryProvider = ({ children }: { children: React.ReactNode }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
export default QueryProvider;
