import axios from "axios";
import {
  IJsonResponse,
  BASE_URL,
  commonAxiosConfig,
  IResultResponse,
} from "rest/common";

export interface IPublicAvailabilities {
  user_id: number;
  firstname: string;
  message: string;
  phone?: string;
}

interface IPublicAvailabilitiesResponse extends IResultResponse {
  result: IPublicAvailabilities[];
}

const api = {
  getPublicAvailabilities: () => {
    return axios
      .get<null, IJsonResponse<IPublicAvailabilitiesResponse>>(
        `${BASE_URL}/public_availabilities`,
        commonAxiosConfig
      )
      .then((res) => {
        return res.data;
      });
  },
  addPublicAvailability: (data: { message: string }) => {
    return axios
      .post<null, IJsonResponse<IResultResponse>>(
        `${BASE_URL}/public_availabilities`,
        data,
        commonAxiosConfig
      )
      .then((res) => {
        return res.data;
      });
  },

  editPublicAvailability: (data: {
    message: string;
    hidden: boolean | undefined;
  }) => {
    return axios
      .put<null, IJsonResponse<IResultResponse>>(
        `${BASE_URL}/public_availabilities`,
        data,
        commonAxiosConfig
      )
      .then((res) => {
        return res.data;
      });
  },
};
export default api;
