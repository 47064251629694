import { Actions, Categories } from "analytics/events";
import Modal, { ModalActions } from "components/Modal";
import { useAnalyticsConext } from "contexts/AnalyticsContext";
import { UserContext } from "contexts/UserContext";
import {
  MatchWithUserDetailsModel,
  UserBaseModel,
} from "north-manly-squash-api";
import { AcceptChallenge } from "pages/Ladder/challenges";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import API from "rest/api";
import style from "./style.module.scss";

const Wrapper = () => {
  const { user } = useContext(UserContext);
  if (!user) return null;
  return <PendingChallenges {...user} />;
};

const ChallengesModal = ({
  setOpen,
  matches,
}: {
  setOpen: (open: boolean) => void;
  matches: MatchWithUserDetailsModel[];
}) => {
  const { setEvent } = useAnalyticsConext();
  useEffect(() => {
    setEvent(Actions.challenge_pending_modal, Categories.modal);
  }, [setEvent]);
  return (
    <Modal setOpen={setOpen}>
      <h2>
        You have {matches.length} pending challenge
        {matches.length > 1 && "s"}.
      </h2>
      {matches.map((challenge) => (
        <div className={style.wrapper}>
          <div className={style.text}>
            {challenge.player_1_firstname} challenged you
          </div>
          <AcceptChallenge match={challenge} skipCheckModal />
        </div>
      ))}
      <ModalActions
        primaryText="Remind me later"
        onClickPrimary={() => setOpen(false)}
      />
    </Modal>
  );
};

const PendingChallenges = ({ id }: UserBaseModel) => {
  const { data, isSuccess } = API.ladder.useGetMatches({
    state: "PENDING_USER",
    userId: id,
  });

  const [open, setOpen] = useState(true);

  if (!data || !isSuccess) return null;

  const { matches } = data;
  const pendingYou = matches.filter((match) => match.player_2 === id);
  if (pendingYou.length === 0) return null;
  if (!open) return null;

  return <ChallengesModal setOpen={setOpen} matches={pendingYou} />;
};

export default Wrapper;
